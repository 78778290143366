import "./assets/css/materialdesignicons.min.css";
import "./Apps.scss";
// import { Container, Row, Col } from "reactstrap";

import "./assets/css/colors/default.css";
import Topnav from "./components/Layout/Topnav";
import {
  Route,
  Switch,
  BrowserRouter as Router,
  withRouter,
} from "react-router-dom";

// Import images

// import routes from "./routes";
import Home from "./pages/Home";
import Footer from "./components/Layout/Footer";
import Clientele from "./pages/Clientele";
import ContactUs from "./pages/ContactUs";
import WhyUs from "./pages/WhyUs";
import Products from "./pages/Products";
import ScrollToTop from "./components/Shared/ScrollToTop";
import PageService from "./pages/PageService";

function App() {
  return (
    <>
    <Router>
      <ScrollToTop />
    <Topnav/>
    <Switch>
      <Route exact path="/">
      <Home />
      </Route>
      <Route path="/contactus">
        <ContactUs/>
      </Route>
      <Route path="/clientele">
        <Clientele/>
      </Route>
      <Route path="/products">
        <Products/>
      </Route>
      <Route path="/whyus">
        <WhyUs/>
      </Route>
      <Route path="/services">
        <PageService/>
      </Route>
    </Switch>
    <Footer/>
    </Router>
      
    </>
  );
}

export default App;
