
import { Container, Row, Col } from "reactstrap";
import HeroSlider from "../components/Slider/HeroSlider";

// Import images
import Services from "./Services";
import Counter from "./Counter";
import Blogs from "./Blogs";

function Home() {
  return (
    <>
      <HeroSlider/>
      <section className="section pt-0">
            <Container>
              <Row>
              <Col md={4} xs={12} name="featurebox">
              <div className='features text-center'>
              <div className="image position-relative d-inline-block">
                      <i className='uil uil-airplay h1 text-primary'>
                      </i>
                    </div>
                    <div className="content mt-4">
                      <h5>Excellent UI/UX</h5>
                      <p className="text-muted mb-0">Modern day software solution is all about UI/UX. Make your customer feel at home the moment they come in interaction with your mobile or web application</p>
                    </div>
              </div>
              </Col>
              <Col md={4} xs={12} name="featurebox">
              <div className='features text-center'>
              <div className="image position-relative d-inline-block">
          
                      <i className='uil uil-mobile-vibrate h1 text-primary'>
                      </i>
                    </div>
                    <div className="content mt-4">
                      <h5>Responsive Design</h5>
                      <p className="text-muted mb-0">With flexible grids and layouts, images, an intelligent use of CSS media queries, all our application works seamlessly on any device be it any mobile, tablet or desktop PC</p>
                    </div>
              </div>
            </Col>
            <Col md={4} xs={12} name="featurebox">
              <div className='features text-center'>
              <div className="image position-relative d-inline-block">
            
                      <i className='uil uil-circuit h1 text-primary'>
                      </i>
                    </div>
                    <div className="content mt-4">
                      <h5>Latest Technologies</h5>
                      <p className="text-muted mb-0">Work with technology meant to last the next few decades. Languages we proudly talk Golang, TS & JS. Other stuff we breath and live with : Next.js, Node.js, MongoDB, React & MySQL</p>
                    </div>
              </div>
              
              </Col>
              </Row>
            </Container>
      </section>
      <Counter/>
      <Services/>
      <Blogs/>
      
    </>
  );
}

export default Home;
