import { Col, Container, Row } from "reactstrap";

export default function Services() {
    return (
        <section className="section" id="services">
            <Container className="pb-lg-4 mb-md-5 mb-4">
            <Row className="justify-content-center">
          <Col xs="12" className= "text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
               Services
              </h4>
              <p
                className= "text-muted para-desc mx-auto mb-0" >
                We provide a range of services from mobile/web application development to tailored requirements for any software related project as long as its within our tech stack 
              </p>
            </div>
          </Col>
          <Col md={4} xs={12} name="featurebox" className='mt-4'>
            <div className='features text-center'>
            <div className="image position-relative d-inline-block">
                    <i className='uil uil-airplay h1 text-primary'>
                    </i>
                  </div>
                  <div className="content mt-4">
                    <h5>Web Applications</h5>
                    <p className="text-muted mb-0">
                      Are you a start-up or running a heritage old business? Either ways you need to a web application for your business before you start losing your customers!</p>
                  </div>
            </div>
            </Col>
            <Col md={4} xs={12} name="featurebox" className='mt-4'>
            <div className='features text-center'>
            <div className="image position-relative d-inline-block">
         
                    <i className='uil uil-mobile-vibrate h1 text-primary'>
                    </i>
                  </div>
                  <div className="content mt-4">
                    <h5>Mobile Applications</h5>
                    <p className="text-muted mb-0">
                      Got a new idea which will disrupt an industry? Start testing the waters with a MVP of your mobile application before walking the whole nine yard. Go for it!</p>
                  </div>
            </div>
           </Col>
           <Col md={4} xs={12} name="featurebox" className='mt-4'>
            <div className='features text-center'>
            <div className="image position-relative d-inline-block">
           
                    <i className='uil uil-circuit h1 text-primary'>
                    </i>
                  </div>
                  <div className="content mt-4">
                    <h5>AI Solutions</h5>
                    <p className="text-muted mb-0">
                      How can AI impact my business? Let us help find the answer for you. But yeah, there ain't any industry which wont be touched by AI in the next 10 years. Gear up!</p>
                  </div>
            </div>
            
            </Col>
            <Col md={4} xs={12} name="featurebox" className='mt-4'>
            <div className='features text-center'>
            <div className="image position-relative d-inline-block">
           
                    <i className='uil uil-data-sharing h1 text-primary'>
                    </i>
                  </div>
                  <div className="content mt-4">
                    <h5>API Development</h5>
                    <p className="text-muted mb-0">
                      Do you have any idea what "API" did to the world of computing, especially REST API? Heck! It changed the way internet worked forever. Keep it simple - get some API's!</p>
                  </div>
            </div>
            
            </Col>
            <Col md={4} xs={12} name="featurebox" className='mt-4'>
            <div className='features text-center'>
            <div className="image position-relative d-inline-block">
           
                    <i className='uil uil-post-stamp h1 text-primary'>
                    </i>
                  </div>
                  <div className="content mt-4">
                    <h5>Internet of Things</h5>
                    <p className="text-muted mb-0">
                      How about placing a IC on your chair so that it can remind you to take a walk if you were to sit for more than 90 minutes? Welcome to the connected world!</p>
                  </div>
            </div>
            
            </Col>
            <Col md={4} xs={12} name="featurebox" className='mt-4'>
            <div className='features text-center'>
            <div className="image position-relative d-inline-block">
           
                    <i className='uil uil-cloud-shield h1 text-primary'>
                    </i>
                  </div>
                  <div className="content mt-4">
                    <h5>Cloud Deployment</h5>
                    <p className="text-muted mb-0">
                      So you think you dont need Cloud? La, rethink! Everyone is adapting Cloud - SME, MME, MNC even the solo entrepreneur. And they are going it for good!</p>
                  </div>
            </div>
            
            </Col>
        </Row>
            </Container>
        </section>
    )
}
