import { Link } from "react-router-dom";
import { Card, CardBody, CardImg, Col, Container, Row } from "reactstrap";

// Blog Images
import blog1 from "../assets/images/hotel/bg01.jpg";
import blog2 from "../assets/images/hotel/bg02.jpg";
import blog3 from "../assets/images/hotel/bg03.jpg";

export default function Blogs() {
    return (
        <section className="section pt-5 pt-sm-0 pt-md-4 bg-light">
        <Container>
        <Row className="justify-content-center">
          <Col xs="12" className="text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
              Latest Blogs
              </h4>
              <p
                className="text-muted para-desc mx-auto mb-0" >
                Learn about some of the interesting stuff happening at {" "}
                <span className="text-primary fw-bold">Web55</span>{" "}
                where we talk about some of the challenges we face with technology and also client success stories which are unique
              </p>
            </div>
          </Col>
        </Row>
        <Row>

        <Col lg="4" md="6" className="mt-4 pt-2" name="blog">
            <Card className="blog rounded border-0 shadow">
              <div className="position-relative">
                <CardImg top src={blog1} className="rounded-top" alt="" />
                <div className="overlay rounded-top bg-dark"></div>
              </div>
              <CardBody className="content">
                <h5>
                  <Link to="#" className="card-title title text-dark">
                  MongoDB - Atlas or Self Hosted? Things you should know
                  </Link>
                </h5>
                <div className="post-meta d-flex justify-content-between mt-3">
                  <ul className="list-unstyled mb-0">
                    <li className="list-inline-item me-2  mb-0">
                      <Link to="#" className="text-muted like">
                        <i className="uil uil-heart me-1"></i>
                        51
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-muted comments">
                        <i className="uil uil-comment me-1"></i>
                        18
                      </Link>
                    </li>
                  </ul>
                  <Link to="/whyus" className="text-muted readmore">
                    Read More <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </CardBody>
              <div className="author">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> Syed Lanka
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 13th August, 2021
                </small>
              </div>
            </Card>
          </Col>
          <Col lg="4" md="6" className="mt-4 pt-2" name="blog">
            <Card className="blog rounded border-0 shadow">
              <div className="position-relative">
                <CardImg top src={blog3} className="rounded-top" alt="" />
                <div className="overlay rounded-top bg-dark"></div>
              </div>
              <CardBody className="content">
                <h5>
                  <Link to="#" className="card-title title text-dark">
                  Why not just Mobile Apps? Why web?
                  </Link>
                </h5>
                <div className="post-meta d-flex justify-content-between mt-3">
                  <ul className="list-unstyled mb-0">
                    <li className="list-inline-item me-2  mb-0">
                      <Link to="#" className="text-muted like">
                        <i className="uil uil-heart me-1"></i>
                        33
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-muted comments">
                        <i className="uil uil-comment me-1"></i>
                        08
                      </Link>
                    </li>
                  </ul>
                  <Link to="/whyus" className="text-muted readmore">
                    Read More <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </CardBody>
              <div className="author">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> Irfan
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 22nd March, 2021
                </small>
              </div>
            </Card>
          </Col>
          <Col lg="4" md="6" className="mt-4 pt-2" name="blog">
            <Card className="blog rounded border-0 shadow">
              <div className="position-relative">
                <CardImg top src={blog2} className="rounded-top" alt="" />
                <div className="overlay rounded-top bg-dark"></div>
              </div>
              <CardBody className="content">
                <h5>
                  <Link to="#" className="card-title title text-dark">
                  The role of images for excellent UI
                  </Link>
                </h5>
                <div className="post-meta d-flex justify-content-between mt-3">
                  <ul className="list-unstyled mb-0">
                    <li className="list-inline-item me-2  mb-0">
                      <Link to="#" className="text-muted like">
                        <i className="uil uil-heart me-1"></i>
                        18
                      </Link>
                    </li>
                    <li className="list-inline-item">
                      <Link to="#" className="text-muted comments">
                        <i className="uil uil-comment me-1"></i>
                        05
                      </Link>
                    </li>
                  </ul>
                  <Link to="/whyus" className="text-muted readmore">
                    Read More <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </CardBody>
              <div className="author">
                <small className="text-light user d-block">
                  <i className="mdi mdi-account"></i> Syed Tariq
                </small>
                <small className="text-light date">
                  <i className="mdi mdi-calendar-check"></i> 11th Jan, 2021
                </small>
              </div>
            </Card>
          </Col>
        </Row>
          
        </Container>
        </section>
    )
}
