// React basic and bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "./pageHeader";
// Import images
import brainy from '../assets/images/site/brainymentor.jpg';
import legal from '../assets/images/site/legal.png';
import product from '../assets/images/enterprise.png'
import qrcode from '../assets/images/site/qr-codew.jpg'
import security from '../assets/images/site/security-.png'

function Products() {

    return (
        <React.Fragment>
           <PageHeader bkimg={product} title="Our Products"/>
            <section className="section mt-md-5 mt-lg-4" id="demos">
                <Container>
                    {/* <Row className="justify-content-center">
                        <Col xs={12}>
                            <div className="section-title mb-4 mb-md-5 text-center">
                                <h5 className="title text-uppercase fw-bold">
                                    Our Products
                                </h5>
                            </div>
                        </Col>
                    </Row> */}
                    <div className="bg-light shadow rounded px-4 py-5 p-md-5">
                        <Row>
                            <Col md={6} xs={12}>
                                <div className="text-center">
                                    <div className="demos-box new-demo rounded shadow text-center border-0">
                                        <Link to={{ pathname: "https://www.brainymentor.com" }} target="_blank">
                                            <img src={brainy} className="img-fluid rounded" alt="demo-img" />
                                        </Link>
                                    </div>
                                    <div className="mt-4">
                                        <h6 className="title text-uppercase font-weight-bold mt-4 mb-0">Brainy Mentor online Learning</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6} xs={12}>
                                <div className="text-center">
                                    <div className="demos-box new-demo rounded shadow text-center border-0">
                                        <Link to="#" target="_blank">
                                            <img src={qrcode} className="img-fluid rounded h-25 d-inline-block" alt="demo-img" />
                                        </Link>
                                    </div>
                                    <h6 className="title text-uppercase font-weight-bold mt-4 mb-0">QR Pay</h6>
                                </div>
                            </Col>
                            
                        </Row>
                        <Row className='mt-4'>
                            <Col md={6} xs={12}>
                                <div className="text-center">
                                    <div className="demos-box new-demo rounded shadow text-center border-0">
                                        <Link to='/products' target="_blank">
                                            <img src={security} className="img-fluid rounded" alt="demo-img" />
                                        </Link>
                                    </div>
                                    <div className="mt-4">
                                        <h6 className="title text-uppercase font-weight-bold mt-4 mb-0">Cyber Security</h6>
                                    </div>
                                </div>
                            </Col>

                            <div className="col-md-6 col-12 mt-5 mt-sm-0">
                                <div className="text-center">
                                    <div className="demos-box new-demo rounded shadow text-center border-0">
                                        <Link to="#" target="_blank">
                                            <img src={legal} className="img-fluid rounded" alt="demo-img" />
                                        </Link>
                                    </div>
                                    <h6 className="title text-uppercase font-weight-bold mt-4 mb-0">Online Legal Consulting</h6>
                                </div>
                            </div>
                            
                        </Row>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}
export default Products;
