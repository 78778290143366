// React Basic and Bootstrap
import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

//Import components
// import PageBreadcrumb from "../components/Shared/PageBreadcrumb";


// import images
import about from "../assets/images/about.jpg";
import grow from "../assets/images/finance/cta.jpg";
import WorkProcess from "../components/Shared/WorkProcess";
import PageHeader from "./pageHeader";


// Modal Video
// import ModalVideo from "react-modal-video";
// import "../../../node_modules/react-modal-video/scss/modal-video.scss";

class WhyUs extends Component {
  constructor(props:any) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "Web55", link: "/" },
        { id: 2, name: "Page", link: "#" },
        { id: 3, name: "About Us" },
      ],
      
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }

  

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        {/* <PageBreadcrumb title="About Us" pathItems={this.state.pathItems} /> */}
        <PageHeader bkimg={grow} title="Why Us"/>

        {/* <ModalVideo
          channel="youtube"
          isOpen={this.state.isOpen}
          videoId="L61p2uyiMSo"
          onClose={() => this.setState({ isOpen: false })}
        /> */}

        <section className="section">
          <Container>
            <Row className="align-items-center">
              <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="position-relative">
                  <img
                    src={about}
                    className="rounded img-fluid mx-auto d-block"
                    alt=""
                  />
                </div>
              </Col>

              <Col lg={7} md={7} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <div className="section-title ms-lg-4">
                  <h4 className="title mb-4">Our Story</h4>
                  <p className="text-muted">
                    {/* Start working with{" "}
                    <span className="text-primary fw-bold">
                      Web55 Technologies
                    </span>{" "}  */}
                    Web55 Technologies was born out of a desire to build scalable web applications for the modern world using latest technologies. Founded by IT professionals who came from the world of writing firmware and device drivers, we are keen on writing clean and optimized code for front-end and server side applications – that’s why we love Next.js and Golang with a dash of MongoDB.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>         
        </section>
        <section className="section bg-light">
          {/* Work process render */}
          <WorkProcess />
          </section>

    
      </React.Fragment>
    );
  }
}
export default WhyUs;
