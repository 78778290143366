import { NavLink, Link } from 'react-router-dom';
import { useState } from "react";
import logodark from "../../assets/images/logo_web55.png";
import './Layout.css';


export default function Topnav() {

    const [navbar, setNavbar] = useState('');

    const changeNavbarBackground = () => {
        if (window.scrollY >= 300) {
            setNavbar('scrolled');
        }
        else if (window.scrollY <=5){
            setNavbar('');
        }
    };

    window.addEventListener('scroll', changeNavbarBackground);

    return (
        <div className={`top-nav fixed-top ${navbar}`}>
            <div className="container">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/">
                            <img src={logodark} alt="logo" width="132px" height="44px" />
                        </Link>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item">
                                    <NavLink className="nav-link" exact={true} activeClassName="active" to="/"> Home </NavLink>
                                </li>
                                
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link " activeClassName="active" to="/products" > Products </NavLink>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/"> CyberSecurity</Link></li>
                                        <li><Link className="dropdown-item" to="/"> InteliGate</Link></li>
                                        <li><Link className="dropdown-item" to="/"> QR Pay </Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink className="nav-link  " activeClassName="active" to="/services" > Services </NavLink>
                                    <ul className="dropdown-menu">
                                        <li><Link className="dropdown-item" to="/"> Web Applications </Link></li>
                                        <li><Link className="dropdown-item" to="/"> Mobile Applications </Link></li>
                                        <li><Link className="dropdown-item" to="/"> AI Solutions </Link></li>
                                        <li><Link className="dropdown-item" to="/"> Communications API </Link></li>
                                        <li><Link className="dropdown-item" to="/"> Fintech Solutions  </Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/clientele"> Clientèle </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/whyus"> Why Us </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink className="nav-link" activeClassName="active" to="/contactus"> Contact Us </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    )
}
