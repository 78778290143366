import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

// Import images
import saas from "../assets/images/saas/classic01.png";
import CounterBox from "./CounterBox";

export default function Counter() {
    return (
        <section className="section bg-light">
          <Container>
            <Row className="align-items-center">
              <Col
                md={{ size: 6, order: 1 }}
                xs={{ order: 2 }}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0"
              >
                <div className="section-title me-lg-5">
                  <h4 className="title mb-4">
                    Manage your business from a simple yet powerful dashboard
                  </h4>
                  <p className="text-muted">
                  Having a easy to navigate and pleasing to the eye admin dashboard is the core of your application requirement. When you deploy your mobile or web application, you instantly need to know whats happening out there - how many visits or downloads you got, what are the sales number, which particular product/feature is doing well, what time of the day or week do we got high activity. You name it, we got it covered.
                  </p>
                  <Link to="#" className="btn btn-outline-primary">
                    Start Now <i className="mdi mdi-chevron-right"></i>
                  </Link>
                </div>
              </Col>

              <Col md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
                <img src={saas} className="img-fluid" alt="" />
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="justify-content-center" id="counter">
              <CounterBox />
            </Row>
          </Container>
        </section>
    )
}
