import { Col, Row } from "reactstrap";
import CountUp from "react-countup";



export default function CounterBox() {
    return (
        
            <Row>

                <Col md={4}  className="mt-4 pt-2">
                  <div className="counter-box text-center px-lg-4">
                    <h2 className="mb-0 text-primary display-4">
                      <span className="counter-value">
                        <CountUp end={95} duration={8} />
                      </span>
                      %
                    </h2>
                    <h5 className="counter-head">Happy Customers</h5>
                    <p className="text-muted mb-0">
                    At the end of the day, we want to see our clients happy. Going the extra mile to achieve it is the DNA of Web55.
                        </p>
                  </div>
                </Col>
                  <Col md={4}  className="mt-4 pt-2">
                  <div className="counter-box text-center px-lg-4">
                    <h2 className="mb-0 text-primary display-4">
                      <span className="counter-value">
                        <CountUp end={85} duration={8} />
                      </span>
                      %
                    </h2>
                    <h5 className="counter-head">Returning Customers</h5>
                    <p className="text-muted mb-0">
                    A happy customer is always likely to come back and work with us again - what we witness at Web55.
                    </p>
                  </div>
                </Col>
                <Col md={4}  className="mt-4 pt-2">
                  <div className="counter-box text-center px-lg-4">
                    <h2 className="mb-0 text-primary display-4">
                      <span className="counter-value">
                        <CountUp end={100} duration={8} />
                      </span>
                      %
                    </h2>
                    <h5 className="counter-head">100% Uptime</h5>
                    <p className="text-muted mb-0">
                    Not only our servers are up all the time, at Web55 we respond to emergency situations immediately.
                        </p>
                  </div>
                </Col>
            </Row>
    )
}
