import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";

import services from "../../assets/images/illustrator/services.svg";

export default function HeroSlider() {
    return (
        <section className="bg-half-170 d-table w-100" id="home">
             <Container>
            <Row className="align-items-center">
              <Col lg={7} md={7}>
                <div className="title-heading mt-4">
                  <h1 className="heading mb-3">
                    Build Anything <br />
                    For Your Project
                  </h1>
                  <p className="para-desc text-muted">
                  Work with Web55 Technologies to create cutting edge technological solutions to drive business with peace of mind
                  </p>
                  <div className="mt-4 pt-2">
                  <Link
                      to="/services"
                      className="btn btn-primary m-1"
                    >
                      Our Services
                    </Link>
                    <Link to='/contactus' className="btn btn-outline-primary rounded ms-1">
                   
                  
                        Get in Touch
                        </Link>
                  </div>
                  </div>
                  </Col>
                  <Col lg={5} md={5} className="mt-4 pt-2 mt-sm-0 pt-sm-0">
                <img src={services} alt="" />
              </Col>
                  </Row>
                  </Container>
        </section>
    )
}
