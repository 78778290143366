import { Link } from "react-router-dom";
import logodark from "../../assets/images/logo_web55.png";
import { FaFacebookF, FaYoutube, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import './Layout.css';

export default function Footer() {
    return (
            <>
              <footer className="footer bg-dark">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-xs-12 mb-0 mb-md-4 pb-0 pb-md-2">
                      <Link to="#" className="logo-footer">
                        <img src={logodark} width="132" height="44" alt="" />
                      </Link>
                      <p className= "mt-4 text-muted">
                        We live amidst a technological era. Technology has become one of the fundamental aspect of life, and its influence is ever growing with each passing day
                      </p>
                      <ul className="list-unstyled social-icon social mb-0 m t-4">
                        <li className="list-inline-item me-1">
                          <a href="https://www.facebook.com" target="_blank" className="rounded"> 
                            <FaFacebookF />
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                        <a href="https://www.linkedin.com/company/web55-technologies/" target="_blank" className="rounded"> 
                            <FaLinkedinIn />                                    
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                        <a href="https://www.youtube.com/channel/UCcc9raTX_k6fel-x0wBZDRg" target="_blank" className="rounded"> 
                            <FaYoutube />                                    
                          </a>
                        </li>
                        <li className="list-inline-item me-1">
                        <a href="https://twitter.com/" target="_blank" className="rounded"> 
                            <FaTwitter />                                    
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-xs-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="text-light footer-head"> 
                        Company 
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">                    
                        <li>
                          <Link to='/whyus' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            About Us
                          </Link>
                        </li> 
                        <li>
                          <Link to='/contactus' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Contact Us
                          </Link>
                        </li> 
                        <li>
                          <Link to='/services' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Services
                          </Link>
                        </li> 
                        <li>
                          <Link to='/products' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Products
                          </Link>
                        </li> 
                        <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Careers
                          </Link>
                        </li> 
                        <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>{" "}
                            Blog
                          </Link>
                        </li>                
                      </ul>
                    </div>
                    <div className="col-lg-2 col-md-4 col-xs-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="text-light footer-head">
                        Usefull Links
                      </h5>
                      <ul className="list-unstyled footer-list mt-4">
                        <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Terms of Services
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Privacy Policy
                          </Link>
                        </li> 
                        <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Documentation
                          </Link>
                        </li>
                        {/* <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Changelog
                          </Link>
                        </li>
                        <li>
                          <Link to='/' className="text-muted">
                            <i className="mdi mdi-chevron-right me-1"></i>
                            Components
                          </Link>
                        </li>                    */}
                      </ul>
                    </div>
                    <div className="col-lg-3 col-md-4 col-xs-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                      <h5 className="text-light footer-head">
                        Newsletter
                      </h5>
                      <p className="mt-4">
                        Sign up and receive the latest tips via email.
                      </p>
                      <form>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="foot-subscribe mb-3 foot-white">
                              <label className= "form-label text-muted">
                                Write your email{" "}
                                <span className="text-danger">*</span>
                              </label>                            
                              <input
                                type="email"
                                name="email"
                                id="emailsubscribe"
                                className="p-2 rounded bg-light border form-control"                               
                                placeholder="Your email : abdul@web55.solutions"
                                required
                              />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submitsubscribefooter"
                                name="send"
                                className="btn btn-primary"
                                readOnly
                                value="Subscribe"
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </footer>
            </>
    )
}
