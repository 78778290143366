// React basic and bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "./pageHeader";

// Import images
import brainy from '../assets/images/site/brainymentor.jpg';
import legal from '../assets/images/site/legal.png';
import grow from "../assets/images/job/company.jpg";
import Services from "./Services";


function PageService() {

    return (
        <React.Fragment>
            <PageHeader bkimg={grow} title="Our Services"/>

            <Services />
        </React.Fragment>
    );
}
export default PageService;
