// React basic and bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "./pageHeader";

// Import images
import brainy from '../assets/images/site/brainymentor.jpg';
import legal from '../assets/images/site/legal.png';
import grow from "../assets/images/job/company.jpg";


function Clientele() {

    return (
        <React.Fragment>
            <PageHeader bkimg={grow} title="Our Clients" />

            <section className="section mt-md-5 mt-lg-4" id="demos">
                <Container>

                    <div className="bg-light shadow rounded px-4 py-5 p-md-5">
                        <Row className="row justify-content-around">
                            <Col md={6} xs={12}>
                                <div className="text-center">
                                    <div className="demos-box new-demo rounded shadow text-center border-0">
                                        <Link to={{ pathname: "https://www.brainymentor.com" }} target="_blank">
                                            <img src={brainy} className="img-fluid rounded" alt="LMS-img" />
                                        </Link>
                                    </div>
                                    <div className="mt-4">
                                        <h6 className="title text-uppercase font-weight-bold mt-4 mb-0">Brainymentor LMS</h6>
                                    </div>
                                </div>
                            </Col>

                            <Col md={6} xs={12}>
                                <div className="text-center">
                                    <div className="demos-box new-demo rounded shadow text-center border-0">
                                    <Link to={{ pathname: "http://legal.zytcode.com/" }} target="_blank">
                                            <img src={legal} className="img-fluid rounded" alt="Legal-img" />
                                        </Link>
                                    </div>
                                    <div className="mt-4">
                                        <h6 className="title text-uppercase font-weight-bold mt-4 mb-0">Al Muhie Legal</h6>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </section>
        </React.Fragment>
    );
}
export default Clientele;
