import React, { Component } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

export default class WorkProcess extends Component {
  render() {
    return (
      <React.Fragment>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12}>
              <div className="section-title text-center mb-4 pb-2">
                <h6 className="text-primary">Our Process</h6>
                <h4 className="title mb-4">This is how we get things done!</h4>
                <p className="text-muted para-desc mx-auto mb-0">
                  {/* Start working with{" "}
                  <span className="text-primary fw-bold">
                    Web55 Technologies
                  </span>{" "} */}
                  There are three main stages, we follow a strict process at each stage so that we deliver what we promise and keep our customers happy!
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col md={4} className="mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-presentation-edit d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Brainstorming for SoW & UI/UX</h5>
                  <p className="text-muted mb-0">
                    Lots of questions to be answered here. Understanding your business helps us create the right SoW. Then comes UI/UX.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-3 mt-4 pt-2">
              <Card className="card features feature-clean work-process bg-transparent process-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-airplay d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Development & Testing</h5>
                  <p className="text-muted mb-0">
                    Based on the chosen tech stack, we start development in a Agile manner. Every code written is documented / tested well.
                  </p>
                </CardBody>
              </Card>
            </Col>

            <Col md={4} className="mt-md-5 pt-md-5 mt-4 pt-2">
              <Card className="features feature-clean work-process bg-transparent d-none-arrow border-0 text-center">
                <div className="icons text-primary text-center mx-auto">
                  <i className="uil uil-image-check d-block rounded h3 mb-0"></i>
                </div>

                <CardBody>
                  <h5 className="text-dark">Deployment & Monitoring</h5>
                  <p className="text-muted mb-0">
                    Cloud or Private server? We have you covered for deploying your app for a million users!
                  </p>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
