// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import PageHeader from "./pageHeader";

//Import components
import PageBreadcrumb from "../components/Shared/PageBreadcrumb";

//Import Icons
// @ts-ignore
import FeatherIcon from "feather-icons-react";

// import images
import contactImg from "../assets/images/contact-detail.jpg";
import contact from "../assets/images/contact.svg";


class ContactUs extends Component {
  constructor(props :any ) {
    super(props);
    this.state = {
      pathItems: [
        //id must required
        { id: 1, name: "Web55", link: "/" },
        { id: 2, name: "Page", link: "#" },
        { id: 3, name: "Contact", link: "#" },
        { id: 4, name: "Contact One" },
      ],
      Contactvisible: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.sendMail.bind(this);
    this.callNumber.bind(this);
  }

  handleSubmit(event:any) {
    event.preventDefault();
    this.setState({ Contactvisible: true });
  }

  // componentDidMount() {
  //   document.body.classList = "";
  //   window.addEventListener("scroll", this.scrollNavigation, true);
  // }

  // Make sure to remove the DOM listener when the component is unmounted.
  // componentWillUnmount() {
  //   window.removeEventListener("scroll", this.scrollNavigation, true);
  // }

  // scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (top > 80) {
  //     document.getElementById("topnav").classList.add("nav-sticky");
  //   } else {
  //     document.getElementById("topnav").classList.remove("nav-sticky");
  //   }
  // };

  sendMail() {
    window.location.href = "mailto:info@web55.tech";
  }

  callNumber() {
    window.location.href = "tel:+971-4-225-5324";
  }

  render() {
    return (
      <React.Fragment>
        {/* breadcrumb */}
        {/* <PageBreadcrumb title="Contact Us" pathItems={this.state.pathItems} /> */}
        <PageHeader bkimg={contactImg} title="Contact Us"/>

        <section className="section pb-0">
          <Container>
            <Row>
              <Col md={4}>
                <Card className="border-0 text-center features feature-clean">
                  <div className="icons text-primary text-center mx-auto">
                    <i className="uil uil-phone d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-3">
                    <h5 className="fw-bold">Phone</h5>
                    <p className="text-muted">
                      We are just a call away, give us a ring and get started right away
                    </p>
                    <Link
                      to="#"
                      onClick={this.callNumber}
                      className="text-primary"
                    >
                      +971-56-515-0595
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 text-center features feature-clean">
                  <div className="icons text-primary text-center mx-auto">
                    <i className="uil uil-envelope d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-3">
                    <h5 className="fw-bold">Email</h5>
                    <p className="text-muted">
                      Send us your enquiry and we will get back to you ASAP
                    </p>
                    <Link
                      to="#"
                      onClick={this.sendMail}
                      className="text-primary"
                    >
                      info@web55.solutions
                    </Link>
                  </div>
                </Card>
              </Col>

              <Col md={4} className="mt-4 mt-sm-0 pt-2 pt-sm-0">
                <Card className="border-0 text-center features feature-clean">
                  <div className="icons text-primary text-center mx-auto">
                    <i className="uil uil-map-marker d-block rounded h3 mb-0"></i>
                  </div>
                  <div className="content mt-3">
                    <h5 className="fw-bold">Location</h5>
                    <p className="text-muted">
                      Drop by for some hot coffee & crazy tech discussions
                      {/* 8th floor, The Offices 4, <br />
                      One Central, Dubai, UAE */}
                    </p>
                    <a href="https://www.google.com/maps/place/Web55+Technologies/@25.2197956,55.2833866,17z/data=!3m1!4b1!4m5!3m4!1s0x3e5f430fe73cc8db:0x950e26fa03ce0f78!8m2!3d25.2197908!4d55.2855753" className="video-play-icon h6 text-primary" rel="noopener noreferrer" target="_blank"> View on Google map</a>
                  </div>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60">
            <Row className="align-items-center">
              <Col
                lg={5}
                md={6}
                className="mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
              >
                <Card className="shadow rounded border-0">
                  <CardBody className="py-5">
                    <h4 className="card-title">Get In Touch !</h4>
                    <div className="custom-form mt-3">
                      <div id="message"></div>
                      {/* <Alert
                        color="primary"
                        isOpen={this.state.Contactvisible}
                        toggle={() => {
                          this.setState({
                            Contactvisible: !this.state.Contactvisible,
                          });
                        }}
                      >
                        Contact details send successfully.
                      </Alert> */}
                      <Form
                        method="post"
                        onSubmit={this.handleSubmit}
                        name="contact-form"
                        id="contact-form"
                      >
                        <Row>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Name <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="user"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="name"
                                id="name"
                                type="text"
                                className="form-control ps-5"
                                placeholder="First Name :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={6}>
                            <div className="mb-3">
                              <Label className="form-label">
                                Your Email{" "}
                                <span className="text-danger">*</span>
                              </Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="mail"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="email"
                                id="email"
                                type="email"
                                className="form-control ps-5"
                                placeholder="Your email :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Subject</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="book"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <Input
                                name="subject"
                                id="subject"
                                className="form-control ps-5"
                                placeholder="Your subject :"
                                required
                              />
                            </div>
                          </Col>
                          <Col md={12}>
                            <div className="mb-3">
                              <Label className="form-label">Comments</Label>
                              <div className="form-icon position-relative">
                                <i>
                                  <FeatherIcon
                                    icon="message-circle"
                                    className="fea icon-sm icons"
                                  />
                                </i>
                              </div>
                              <textarea
                                name="comments"
                                id="comments"
                                rows={4}
                                className="form-control ps-5"
                                placeholder="Your Message :"
                                required
                              ></textarea>
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          <Col sm={12} className="text-center">
                            <div className="d-grid">
                              <input
                                type="submit"
                                id="submit"
                                name="send"
                                className="submitBnt btn btn-primary"
                                value="Send Message"
                              />
                            </div>
                            <div id="simple-msg"></div>

                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={7} md={{ size: 6, order: 2 }} xs={{ order: 1 }}>
                <Card className="border-0">
                  <CardBody className="p-0">
                    <img src={contact} className="img-fluid" alt="web55.tech" />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Container className="mt-100 mt-60" fluid>
            <Row>
              <Col xs={12} className="p-0">
                <Card className="map border-0">
                  <CardBody className="p-0">
                    <iframe
                      title="myFrame"
                      src="//www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3609.5084076538888!2d55.28338661405705!3d25.219795636974304!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f430fe73cc8db%3A0x950e26fa03ce0f78!2sWeb55%20Technologies!5e0!3m2!1sen!2sin!4v1632395521012!5m2!1sen!2sin"
                      style={{ border: "0" }}
                      className="rounded"
                      allowFullScreen
                    ></iframe>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default ContactUs;
